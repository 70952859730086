button:focus{
	outline: none;
	border: none;
}

.guernseysands-button{
	width: 120px;
	height: 48px;
	display: inline-block;
	background: $dark-blue;
	font-size: 18px;
	color: $white;
	box-shadow: none;
	outline: none;
	border: none;
	font-weight: 400;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	box-sizing: content-box;
	padding: 0;
	margin-left: 2.5px;
	vertical-align: top;
	transition: background ease 0.5s;
}

.guernseysands-button:hover{
	background: $brand-primary;
	transition: background ease 0.5s;
}
/*Wrappers & Containers*/

.guernseysands-wrapper{
	background: url('../images/guernseysands-dandelion-bg.png') no-repeat bottom left $light-blue;
	padding: 50px 0 60px;
}

.guernseysands-wrapper-small{
	max-width: 800px;
	margin: 0 auto;
}

.guernseysands-container{
	background: $white;
	padding: 40px;
	margin-bottom: 30px;
}

.guernseysands-container:last-child{
	margin-bottom: 0;
}

.guernseysands-container p:last-child{
	margin-bottom: 0;
}

.guernseysands-container .date{
	color: $grey;
}

.guernseysands-container hr{
	margin: 30px 0;
	height: 2px;
	background: $brand-primary;
	outline: none;
	border: none;
	box-shadow: none;
}

/*Sidebar*/

.guernseysands-sidebar-header{
	margin-bottom: 30px;
	padding-left: 20px;
}

.guernseysands-sidebar-header span{
	color: $dark-blue;
	font-size: 20px;
	text-transform: uppercase;
	line-height: 1em;
	font-weight: 400;
}

.sidebar-nav{
	padding: 0;
	margin: 0;
	list-style: none;
}

.sidebar-nav a:before{
	content: '» ';
}

.tinynav{ 
	display: none
}

/*Home*/

.guernseysands-wrapper-home-slider{
	background: $light-blue;
	padding: 30px 0 24px;
}

.guernseysands-home-slider-slide{
	height: 600px;
	padding: 30px 30px 0;
	background-position: center center;
	background-size: cover;
}

.guernseysands-home-slider-slide-content{
	position: absolute;
	bottom: 0;
	left: 0;
	background: url('../images/guernseysands-slider-icon-bg.svg') rgba(0,0,0,0.6);
	padding: 30px;
	width: 100%;
	box-sizing: padding-box;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: contain;
	background-origin: content-box;
}

.guernseysands-home-slider-slide-content p{
	font-size: 32px;
	line-height: 1em;
	color: $white;
	margin-bottom: 23.5px;
	width: 70%;
}

.guernseysands-slider-button {
    height: 48px;
    background: $brand-primary;
    font-size: 15px;
    color: #fff;
    box-shadow: none;
    font-weight: 400;
    border-radius: 5px;
	padding: 0 15px;
    text-transform: uppercase;
    border: none;
    outline: none;
}


.guernseysands-wrapper-home-welcome{
	padding: 80px 0 40px;
	background: $white;
	border-top: 1px solid $light-grey;
	border-bottom: 1px solid $light-grey;
}

.guernseysands-wrapper-home-welcome h2{
	line-height: 1.5em;
	margin-bottom: 1.5rem;
}

.guernseysands-wrapper-home-welcome p{
	font-size: 20px;
	line-height: 1.5em;
	margin-bottom: 1.5rem;
}

.guernseysands-wrapper-home-welcome img{
	width: 100%;
	height: auto;
}

.guernseysands-wrapper-home-latest-news{
	background: url('../images/guernseysands-dandelion-bg.png') no-repeat bottom left $light-blue;
	padding: 55px 0 60px;	
}

.guernseysands-wrapper-home-latest-news h4{
	font-size: 24px;
	font-weight: 400;
	text-transform: uppercase;
	color: $dark-blue;
	text-align: center;
	margin: 0;
	line-height: 1em;
}

.guernseysands-wrapper-home-latest-news h4:after {
    display: block;
    height: 4px;
    background-color: #f99000;
    content: " ";
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    margin-top: 27px;
}

.guernseysands-home-latest-news-container{
	margin-top: 30px;
}

.guernseysands-home-news-post-preview-image{
	height: 220px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.guernseysands-home-news-post-preview .date{
	color: $grey;
	margin-bottom: 10px;
}

.guernseysands-home-news-post-preview-content{
	padding: 30px;
	background: $white;
	border-top: 2px solid $brand-primary;
}

.guernseysands-home-news-post-preview-content h3{
	line-height: 1.2em;
}

.guernseysands-home-news-post-preview-excerpt p:first-child{
	min-height: 72px;
}

.guernseysands-home-news-post-preview-content p:last-child{
	margin-bottom: 0;
}

/*About us*/

.guernseysands-container-about{
	background: $white;
	padding: 40px;
}

.guernseysands-container-about p:last-child{
	margin-bottom: 0;
}

.guernseysands-container-about ul:last-child{
	margin-bottom: 0;
}

.guernseysands-container-about h1{
	color: $black;
}

.guernseysands-container-about h2{
	margin-top: 30px;
	margin-bottom: 5px;
}

.guernseysands-container-about h3{
	margin-top: 30px;
}

.guernseysands-container-about img{
	width: 100%;
	height: auto;
	margin-bottom: 1rem;
}

.guernseysands-container-about hr{
	margin: 30px 0 0;
}

.gallery-h3{
	border-bottom: 1px solid $light-grey;
	padding-bottom: 12.5px;
	margin-bottom: 15px;
	font-size: 16px;
	text-transform: uppercase;
	color: $dark-blue;
	font-weight: 400;
}

.guernseysands-gallery{
	padding: 0 7.5px;
	margin-bottom: -15px;
}

.guernseysands-gallery a{
	transition: opacity ease 0.5s;
}

.guernseysands-gallery a:hover{
	opacity: 0.8;
	transition: opacity ease 0.5s;
}

.guernseysands-gallery img{
	width: 100%;
	height: auto;
}

.guernseysands-gallery .col-md-3{
	padding: 0 7.5px;
}

.guernseysands-gallery .col-sm-4{
	padding: 0 7.5px;
}

.guernseysands-gallery .col-xs-4{
	padding: 0 7.5px;
}

.guernseysands-sidebar{
	background: $sidebar-blue;
	padding: 40px;
	border-top: 2px solid $brand-primary;
}

.guernseysands-committee-members h3{
	margin-bottom: 2.5px;
}

.guernseysands-committee-member-title{
	margin-bottom: 10px;
	color: $grey;
}

.guernseysands-whatwedo-preview{
	border-bottom: 1px solid $light-grey;
	padding-bottom: 30px;
	padding-top: 30px;
}

.guernseysands-whatwedo-preview a{
	transition: opacity ease 0.5s;	
}

.guernseysands-whatwedo-preview a:hover{
	opacity: 0.8;
	transition: opacity ease 0.5s;	
}

.guernseysands-whatwedo-preview h2{
	margin-bottom: 1rem;
	margin-top: 0;
}

.guernseysands-committee-members-row{
	border-bottom: 1px solid $light-grey;
	padding-bottom: 30px;	
}

.guernseysands-committee-members-row img{
	margin-top: 30px;
	margin-bottom: 0;
}

.guernseysands-committee-members-row p:last-child{
	margin-bottom: 0;
}

/*Memories*/

.guernseysands-container-memories{
	background: url('../images/guernseysands-butterfly-bg.png');
	background-color: $white;
	background-repeat: no-repeat;
	background-position: right bottom;
}

/*News*/

.guernseysands-container-news{
	background: $white;
	padding: 40px;
}

.guernseysands-container-news p:last-child{
	margin-bottom: 0;
}

.guernseysands-container-news ul:last-child{
	margin-bottom: 0;
}

.guernseysands-container-news h1{
	color: $black;
}

.guernseysands-container-news h2{
	margin-top: 30px;
	margin-bottom: 5px;
}

.guernseysands-container-news h3{
	margin-top: 30px;
}

.guernseysands-container-news hr{
	margin: 30px 0;
	height: 1px;
	background: $light-grey;
	outline: none;
	border: none;
	box-shadow: none;
}

.guernseysands-container-news-post-preview{
	border-bottom: 1px solid $light-grey;
	padding-bottom: 30px;
}

.guernseysands-container-news-post-preview p:last-child{
	margin-bottom: 0;
}

.guernseysands-news-sidebar .widget{
	background: $sidebar-blue;
	padding: 40px;
	border-top: 2px solid $brand-primary;
	margin-bottom: 30px;
}

.guernseysands-news-sidebar .widget:last-child{
	margin-bottom: 0;
}

.guernseysands-news-sidebar .widget h3{
	color: $dark-blue;
	font-size: 20px;
	text-transform: uppercase;
	line-height: 1em;
	font-weight: 400;
	margin-bottom: 30px;
}

.guernseysands-news-sidebar .widget ul{
	list-style: none;
	padding: 0;
}

.guernseysands-news-sidebar .widget ul:last-child{
	margin-bottom: 0;
}

.guernseysands-news-sidebar .widget ul li a:before{
	content: '» ';
}

.guernseysands-single-news-header{
	margin-bottom: 20px;
}

.guernseysands-single-news-header h1{
	margin: 0;
	line-height: 1.2em;
}

.guernseysands-single-news-header hr{
	margin: 20px 0 15px;
}

.guernseysands-single-news-featured-image{
	margin-bottom: 20px;
}

.guernseysands-single-news-featured-image img{
	width: 100%;
	height: auto;
}

.guernseysands-single-news-article p:first-child{
	font-weight: 700;
}

.guernseysands-single-news-article img{
	width: 100%;
	height: auto;
	margin-bottom: 1rem;
}

.guernseysands-share-title{
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.wp-pagenavi{
	margin-top: 30px;
}

/*Shop*/

.guernseysands-product{
	background: white;
	border-bottom: 1px solid $light-grey;
	padding-bottom: 30px;
	margin-bottom: 30px;
}

.guernseysands-product:last-child{
	margin-bottom: 0;
	padding-bottom: 30px;

}

.guernseysands-product-image{
	text-align: center;
}

.guernseysands-product-image a{
	transition: opacity ease 0.5s;
	color: $black;
}

.guernseysands-product-image a:hover{
	opacity: 0.8;
	transition: opacity ease 0.5s;
	color: $black;
}

.guernseysands-product-image img{
	width: auto;
	height: 168px;
	border: 1px solid;
}

.guernseysands-product-price{
	margin-bottom: 5px;
}

.guernseysands-product-content .guernseysands-button{
	width: 150px;
	margin: 0;
}

/*Links*/

.guernseysands-container-links{
	margin-bottom: 30px;
}

.guernseysands-container-links:last-child{
	margin-bottom: 0;
}

.guernseysands-container-links p{
	margin-bottom: 10px;
}

/*Contact*/

.guernseysands-container-contact{
	padding-bottom: 24px;
}

.guernseysands-container-contact h2{
	margin-bottom: 30px;
}

.guernseysands-container-contact h3{
	margin-top: 30px;
}

.guernseysands-container-contact label{
	width: 100%;
}

.guernseysands-container-contact input{
	padding: 5px;
	border: 1px solid $light-grey;
	box-shadow: none;
}

.guernseysands-container-contact [type=submit]{
	padding: 0;
}

.guernseysands-container-contact input[type=submit]:disabled{
	opacity: 0.5;
	cursor: not-allowed;
	background: $dark-blue;
}

.guernseysands-container-contact textarea{
	padding: 10px;
	width: 100%;
	border-color: $light-grey;
}

span.wpcf7-list-item{
	margin: 0;
}

span.wpcf7-list-item label{
	line-height: 1;
}

.guernseysands-acceptance{
	font-size: 0.75rem;
}

p.guernseysands-acceptance{
	line-height: 1.25;
}

/*iUbenda*/

#iubenda_policy.iubenda_fluid_policy .iub_container{
	margin-bottom: 0 !important;
}

#iubenda_policy .iub_content{
	padding: 0 !important;
	font-family: 'Lato', sans-serif !important;
}

#iubenda_policy .iub_content .iub_footer{
	padding-bottom: 0 !important;
}

#iubenda_policy hr{
	background: none !important;
	height: auto !important;
}

#iubenda_policy hr{
	margin: 0 !important;
}
//Header

@media (max-width: 1199px) {
	
	.guernseysands-header .menu-item{
		font-size: 18px;
		padding: 0 10px;
	}
	
	.sub-menu .menu-item{
		padding: 0;
		display: block;
		font-size: 16px;
	}
	
	.guernseysands-wrapper-home-slider .container{
		max-width: 100%;
	}
}

@media (max-width: 991px) {
	
	.guernseysands-header{
		height: auto;
	}
	
	.guernseysands-header-nav-container{
		border: none;
		padding: 0;
	}
	
	.guernseysands-header-left-container{
		float: none;
		text-align: center;
	}
	
	.guernseysands-header-contact-container{
		text-align: center;
	}
	
	.menu-item:hover .sub-menu{
		display: none;
	}
	
	.guernseysands-header-right-container{
		float: none;
	}
	
	.guernseysands-header .menu-item:first-child{
		padding-left: 0;
	}
	
	.guernseysands-header-nav-container{
		text-align: center;
	}
	
	nav{
		display: inline-block;
	}
	
	.guernseysands-header .menu-item{
		font-size: 18px;
		padding: 0 9px;
	}
	
	.guernseysands-wrapper-home-latest-news{
		padding: 60px 0 0;
	}
	
	.guernseysands-home-news-post-preview{
		margin-bottom: 30px;
	}
	
	.guernseysands-home-slider-slide{
		height: 500px;
	}
	
	.guernseysands-home-slider-slide p{
		font-size: 24px;
	}
	
	.guernseysands-sidebar{
		padding: 20px;
	}
	
	.tinynav{
		display: block;
		width: 100%;
	}
	
	#menu-about-us-menu{
		display: none;
	}
	
	.guernseysands-news-sidebar{
		margin-top: 30px;
	}
	
}

@media (min-width: 768px) and (max-width: 991px) {
	header .container{
		max-width: 862px;
	}
}

@media (min-width: 769px) {
	#guernseysands-mobile-nav {
		display:none;
	}
}

@media (max-width: 768px) {
	
	.guernseysands-header{
		padding-bottom: 0;
	}
	
	.guernseysands-wrapper{
		padding: 30px 0 30px;
	}
	
	.guernseysands-wrapper-home-welcome{
		padding: 30px 0;
	}
	
	.guernseysands-home-latest-news-container{
		margin-top: 30px;
	}
	
	.guernseysands-header-nav-container{
		display: none;
	}
	
	.guernseysands-header-contact-container{
		margin-bottom: 0;
	}
	
	#guernseysands-mobile-nav{
		border-top: 1px solid $light-grey;
		margin-top: 15px;
		text-align: center;
	}
	
	.nav-toggle{
		padding: 15px;
		display: block;
		font-size: 16px;
		background: $brand-primary;
	}
	
	.nav-toggle, .nav-toggle:active, .nav-toggle:hover, .nav-toggle:focus{
		color: $white !important;
	}
	
	.nav-collapse .menu-item{
		padding: 7.5px 15px;
		margin: 0;
		font-size: 16px;
		border-top: 1px solid $light-grey;
	}
	
	.nav-collapse .menu-item:first-child{
		padding: 7.5px 15px;
	}

}

@media (max-width: 767px) {
	header .guernseysands-icons{
		display: none;
	}
}

// Footer

@media (max-width: 991px) {

	.guernseysands-footer-mailinglist-button{
		float: left;
		margin-top: 1rem;
	}
	
	.guernseysands-footer-social-icons{
		float: left;
		margin-top: 1rem;		
	}
	
	.guernseysands-footer-copyright-info{
		display: block;
		float: none;
	}
	
	.guernseysands-footer-supported-by{
		display: block;
		float: none;
		margin-top: 1rem;
	}
	
	.guernseysands-sidebar-header{
		margin-bottom: 1rem;
	}
	
	.guernseysands-sidebar{
		margin-bottom: 1rem;
	}
	
	.guernseysands-news-sidebar{
		margin-bottom: 1rem;
	}
	
	.guernseysands-news-sidebar .widget{
		margin-bottom: 1rem;
	}

}

@media (max-width: 767px) {
	
	.guernseysands-product{
		text-align: center;
	}
	
	.guernseysands-product-image{
		margin-bottom: 1rem;
	}
}

@media (max-width: 680px){
	.guernseysands-home-slider-slide{
		height: 400px;
	}
	
	.guernseysands-home-slider-slide p{
		font-size: 20px;
		width: 100%;
	}
	
	.guernseysands-home-slider-slide-content{
		background-image: none;
	}
}

@media (max-width: 480px){
	
	.guernseysands-home-slider-slide{
		height: 260px;
	}
	
	.guernseysands-home-slider-slide-content{
		padding: 15px;
	}
	
	.guernseysands-home-slider-slide p{
		font-size: 18px;
		width: 100%;
		margin-bottom: 12.5px;
	}
	
	.guernseysands-slider-button{
		height: 32px;
		font-size: 12px;
	}
	
	footer .container{
		padding: 0 30px;
	}
	
	.guernseysands-container-contact input{
		width: 100% !important;
	}
	
	.guernseysands-container-contact input[type=checkbox]{
		width: auto !important;
	}
	
	.guernseysands-helpline{
		font-size: 16px;
	}
	
	.guernseysands-header-contact-container .guernseysands-button{
		margin: 5px 0 0;
		font-size: 16px;
		width: 90px;
		height: 38px;
	}
}
/*Global*/

/*Typography*/

body, p{
	font-size: 16px;
	font-family: 'Lato', sans-serif;
	font-style: normal;
	font-weight: 300;
	color: $black;
}

h1{
	font-size: 32px;
	color: $dark-blue;
	margin: 0 0 30px;
	font-weight: 300;
	line-height: 1em;
}

h2{
	font-size: 24px;
	color: $black;
	margin: 0 0 10px;
	font-weight: 300;
	line-height: 1em;
}

h3{
	font-size: 20px;
	color: $black;
	margin: 0 0 10px;
	font-weight: 300;
	line-height: 1em;
}

/*Links*/

a{
	color: $dark-blue;
	transition: color ease 0.5s;
	outline: none;
	text-decoration: none !important;
}

a:hover{
	color: $brand-primary;
	transition: color ease 0.5s;
	text-decoration: none;
}

.grecaptcha-badge {
	display: none !important;
}
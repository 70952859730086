footer{
	border-top: 2px solid $brand-primary;
	padding: 50px 0;
}

.guernseysands-footer-mailinglist{
	display: none;
	margin-bottom: 30px;
}

.guernseysands-footer-mailinglist-content{
	display: block;
	float: left;
}

.guernseysands-footer-mailinglist-content h4{
	font-size: 22px;
	font-weight: 300;
}

.guernseysands-footer-mailinglist-button{
	display: block;
	float: right;
}

.guernseysands-footer-mailinglist-button .guernseysands-button{
	margin-left: 0;
}

.remodal{
	max-width: 400px;
	margin-bottom: 0;
	padding: 35px 35px 30px;
}

.remodal-close{
	left: inherit;
	right: 5px;
	top: 5px;
}

.remodal p{
	font-size: 12px;
	text-align: left;
	margin: 20px 0 0;
}

.remodal label{
	display: block;
	text-align: left;
	margin-bottom: 5px;
	font-weight: 700;
}

.remodal input{
	display: block;
	text-align: left;
	margin-bottom: 10px;
	width: 100%;
	padding: 5px;
}

.remodal [type=submit]{
	text-align: center;
	margin: 20px 0 0;
	padding: 0;
}

.guernseysands-footer-top{
	border-top: 1px solid $light-grey;
    padding-top: 30px;
}

.guernseysands-footer-top a{
	text-decoration: none;
}

.guernseysands-footer-bottom{
	border-top: 1px solid $light-grey;
    margin-top: 30px;
    padding-top: 30px;
}

.guernseysands-footer-charity-info{
	display: block;
	float: left;
}

.guernseysands-footer-social-icons{
	display: block;
	float: right;
}

.guernseysands-footer-copyright-info{
	display: block;
	float: left;
}

.guernseysands-footer-supported-by{
	display: block;
	float: right;
}
.guernseysands-header{
	padding: 10px 0 20px;
	border-bottom: 1px solid $light-grey;
	height: 150px;
	background: $white;
}

.guernseysands-header-left-container{
	display: block;
	float: left;
}

.guernseysands-header-left-container a{
	transition: opacity ease 0.5s;
}

.guernseysands-header-left-container a:hover{
	opacity: 0.8;
	transition: opacity ease 0.5s;		
}

.guernseysands-header-right-container{
	display: block;
	float: right;
}

.guernseysands-header .menu-item{
	font-size: 20px;
	display: inline-block;
	padding: 0 17.5px;
}

.guernseysands-header .menu-item:first-child{
	padding-left: 35px;
}

.guernseysands-header .menu-item:last-child{
	padding-right: 0;
}

.guernseysands-header .menu-item a{
	color: $black;
	text-decoration: none;
}

.guernseysands-header .menu-item a:hover{
	color: $brand-primary;
	text-decoration: none;
}

.guernseysands-header .active a{
	color: $brand-primary;
}

.guernseysands-header-nav-container{
	border-top: 1px solid $light-grey;
	padding-top: 10px;
}

.guernseysands-header-contact-container{
	height: 48px;
	text-align: right;
	margin-bottom: 20px;
	margin-top: 10px;
}

.guernseysands-header-contact-container img{
	display: inline-block;
	margin: 0;
	vertical-align: top;
}

.guernseysands-header-contact-container a{
	text-decoration: none;
}

.guernseysands-header-contact-container .guernseysands-button{
	margin-left: 15px;
}

.guernseysands-helpline{
	display: inline-block;
	color: $brand-primary;
	font-size: 20px;
	font-weight: 400;
	text-decoration: underline;
	line-height: 48px;
	margin-right: 15px;
	background: url('../images/guernseysands-hotline-icon.png');
	background-repeat: no-repeat;
	background-position: left;
	padding-left: 28px;
}

.guernseysands-helpline a{
	color: $brand-primary;
}

.guernseysands-icons{
	transition: opacity ease 0.5s;
}

.guernseysands-icons:hover{
	opacity: 0.6;
	transition: opacity ease 0.5s;
}

/*Drop Down*/

.sub-menu {
    display: none;
    position: absolute;
    background-color: $white;
    min-width: 250px;
    z-index: 9999;
    padding: 20px;
    margin-left: -20px;
    border-bottom: 2px solid $brand-primary;
}

.sub-menu .menu-item{
	padding: 0;
	display: block;
	font-size: 16px;
}

.sub-menu .menu-item:first-child{
	padding: 0;
	display: block;
}

.sub-menu a{
	color: $black !important;
}

.sub-menu a:hover{
	color: $brand-primary !important;
}

.sub-menu .active a{
	color: $brand-primary !important;
}

.menu-item:hover .sub-menu{
    display: block;
}